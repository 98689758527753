import { shuffle } from 'lodash'

export const PROMPTS_LIST = [
  'Someone gets left behind',
  'Someone starts drinking before midday',
  "Someone doesn't make it down in time before breakfast",
  'Someone thinks they have lost their passport',
  'Josh is lying on the floor',
  'Beni is a dad',
  'Group study session',
  'Someone passes out',
  'Someone hooks up with a Hungarian',
  'Alys has a breakdown',
  'Someone looses their key',
  "Daria pulls Josh's arm",
  'Josh gets scared',
  'Josh starts talking about trains',
  'Someone breaks something in their room',
  'Someone tries to speak Hungarian and fails',
  'Someone gets injured',
  'Someone gets pickpocketed',
  'Someone throws up in the bus/bar',
  'A couple starts dancing on the street',
  'CIRCLEEEEE!',
  'Beni puts his eye mask on',
  'Emily goes missing',
  'Someone snores during the night',
  'Someone sleepwalks/sleep-talks',
  'Someone starts a fight',
  'Daria says "excuse youuuuuuuu"',
  'Claire complains about engineering',
  'Josh says "Damn it" to one or all of Daria, Claire, Emily and Alys',
  'Josh gets asked about bingo',
  'Josh pays for a meal',
  'Emily loses her phone',
  '17 simultaneous be-reals',
  'Josh starts speaking German',
  'Tom asks a question',
  'Tom sings',
  'The grandads start causing chaos',
  'Tej makes friends with strangers',
  'Bump into mixed netball or another Warwick club on tour',
  'Fran has energy no one else has',
  'Everyone has to tell Fran not to do something',
  'Tom looks like he really wants to be here',
  'Josh falls asleep',
  'Daria, Josh, Claire, Alys and Em cause utter chaos that makes no sense',
  'Nelson Gingerpears rocks up',
  'Beni is sober',
  'Ivanna has the camera out',
  'Bridget speaks in some random accent',
  'Alcohol is consumed before midday',
  'Someone naps for too long',
  'Tea offers someone a lift',
  "Jokes worse than Mark's are made",
  'Daria insults Josh',
  'Did you know Daria is from Bulgaria?',
  'Beni/Tea is a tour gide',
  'Tom reminds us he is Welsh',
  'Tom does a very bad attempt at an accent',
  'Cat adopts another member of the club'
]

export const PROMPTS = (): string[] => shuffle(PROMPTS_LIST).slice(0, 25)
